import { IBorrowerData, IWidgetState } from "../../interfaces/borrower";
import { ILoanData, IWebState } from "../../interfaces/loanData";
import { IMonoWidgetData } from "../../interfaces/monoWidgetData";
import { BORROWER_DETAILS, LOAN_DETAILS, MONO_WIDGET_DATA, WEB_SETTINGS, WIDGET_SETTINGS } from "./types";

export const updateWidgetSettingsAction = (
  widgetSettings: Partial<IWidgetState>,
) => ({
  type: WIDGET_SETTINGS,
  payload: widgetSettings,
});

export const updateBorrowerDetailsAction = (
  borrowerDetails: Partial<IBorrowerData>,
) => ({
  type: BORROWER_DETAILS,
  payload: borrowerDetails,
});

export const updateLoanDetailsAction = (
  loanDetails: Partial<ILoanData>,
) => ({
  type: LOAN_DETAILS,
  payload: loanDetails,
});

export const updateWebSettingsAction = (
  webSettings: Partial<IWebState>,
) => ({
  type: WEB_SETTINGS,
  payload: webSettings,
});

export const updateMonoWidgetDataAction = (
  monoWidgetData: Partial<IMonoWidgetData>,
) => ({
  type: MONO_WIDGET_DATA,
  payload: monoWidgetData,
});
