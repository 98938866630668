import { ILoanData } from "../../interfaces/loanData";
import moment from "moment";
import { ReduxActionModel } from "../../interfaces/redux-interface";
import { LOAN_DETAILS } from "../actions/types";

const data = localStorage.getItem("currentUser");
const userInfo = data && JSON.parse(data);

let initialState: ILoanData = {
  loan_amount: 0,
  loan_name: "",
  loan_ref: "",
  product_id: 0,
  loan_tenure: 0,
  aggregator_id: 0,
  repayment_start_date: moment().format("DD/MM/YYYY"),
  bvn_checker: "",
  annual_interest_rate: 0,
  aggregator_loan_ref: "", // Date.now().toString(),
  bvn_number: userInfo?.bvn,
  business_registration_number: "",
  phone_number: "", // cleanPhoneSavedPhoneNo(userInfo)
  country_code: "",
  is_bankstatement_linkage_failed: true,
  loan_effective_date: "",
  status: "",
  loanId: 0,
  currency: { code: "" },
  totalPrincipalDisbursed: 0,
  batch_loan_number: null,
  totalInterestCharged: 0,
  loanTermInDays: 0,
  totalRepaymentExpected: 0,
  loan_application_referenceno: "",
  repayment_cycle: "",
  message: "",
  product_name: "",
  repayment_type: "",
  periods: [],
  id: 0,
  maximum_tenor: null,
  maximum_amount: null,
  amount: 0,
  customer_bank_account: "",
  loan_tenor_input_type: "",
  new_loan_offer: {
    offer_amount: null,
    offer_tenor: null,
  },
  new_loan_offer_widget: {
    offer_amount: null,
    offer_tenor: null,
  },
};

export const updateLoanDetailsReducer = (
  state = initialState,
  action: ReduxActionModel,
) => {
  const { type, payload } = action;
  switch (type) {
    case LOAN_DETAILS:
      return { ...state, ...payload };
    default:
      return state;
  }
};
