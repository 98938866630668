import React, { useState, Dispatch, SetStateAction, useEffect } from "react";
import Loader from "../../../../components/Loader/Loader";
import styles from "./OtpVerification.module.scss";
import { Link } from "react-router-dom";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { postData } from "../../../../apis/apiMethods";
import useOtpTypes from "../../../../custom-hooks/useOtpTypes";
import PinInput from "react-pin-input";
import { Alert, Button } from "antd";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import { useDispatch, useSelector } from "react-redux";
import { updateBorrowerDetailsAction } from "../../../../redux/actions";
import { appInsights } from "../../../../components/AppInsight/AppInsight";
import { MixPanel } from "../../../../helpers/mixpanel";

interface OtpVerificationProps {
  onNextStep: () => void;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  success: string;
  setSuccess: Dispatch<SetStateAction<string>>;
}

function OtpVerification({
  onNextStep,
  setCurrentStep,
  error,
  setError,
  success,
  setSuccess,
}: OtpVerificationProps) {
  const [otpLoading, setOtpLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const borrowerData = useSelector(
    (state: ReduxStoreModel) => state.borrowerDetailsReducer,
  );

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web Onboarding  - (OtpVerification.tsx)",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: OtpVerification.tsx`, {
      page: "OtpVerification.tsx - (Web Onboarding)",
    });
  }, []);

  const handleSubmit = async () => {
    setError("");
    setSuccess("");
    setLoading(true);
    try {
      const reqBody = {
        otp,
        email: borrowerData?.email,
        phone: borrowerData?.borrower_phone,
      };
      await postData(apiEndpoints.validate_sign_up_otp, reqBody);
      dispatch(
        updateBorrowerDetailsAction({
          isEmailVerified: true,
        }),
      );
      setLoading(false);
      onNextStep();
    } catch (error) {
      setLoading(false);
      setError("OTP verification failed");
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Onboarding - (OtpVerification.tsx)",
        },
      });

      MixPanel.track("Sign Up", {
        distinct_id: `${borrowerData?.email}`,
        URL: window.location.href,
        product: "Embed Web",
        status: `Failed: ${error.response?.data?.message ?? error.message}`,
        step: "OtpVerification.tsx",
      });
    }
  };

  const { data: otpTypes } = useOtpTypes();

  const resendOtp = async () => {
    setSuccess("");
    setError("");
    setOtpLoading(true);
    try {
      await postData(
        `${apiEndpoints.generate_otp}?email=${borrowerData?.email}&countryCode=${borrowerData?.country}`,
        {
          otpType: otpTypes?.find(
            (o: { name: string }) => o?.name === "Onboarding",
          )?.id,
        },
      );
      setOtpLoading(false);
      setSuccess("A new OTP has been sent to your email address.");
    } catch (error) {
      setOtpLoading(false);
      setError("Operation failed");
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web Onboarding - (OtpVerification.tsx)",
        },
      });
    }
  };

  return (
    <div className={styles.stepTwo_container}>
      {error && (
        <div
          style={{ width: "100%", paddingTop: "20px", paddingBottom: "20px" }}
        >
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => setError("")}
          />
        </div>
      )}
      {success && (
        <div
          style={{ width: "100%", paddingTop: "20px", paddingBottom: "20px" }}
        >
          <Alert
            message={success}
            type="success"
            showIcon
            closable
            onClose={() => setSuccess("")}
          />
        </div>
      )}
      <header className={styles.stepTwo_header}>
        <h1>OTP Verification</h1>
        <p>A One-Time password (OTP) has been sent to {borrowerData?.email}</p>
      </header>

      <div className={styles.stepTwo_form}>
        <div>
          <PinInput
            length={6}
            initialValue=""
            focus
            onChange={(value, index) => {
              setOtp(value);
            }}
            type="numeric"
            inputMode="number"
            autoSelect={true}
            regexCriteria={/^[ 0-9]*$/}
          />
        </div>

        <div
          style={{ display: "flex", gap: "10px" }}
          onClick={resendOtp}
          aria-roledescription="button"
          className={styles.otp}
        >
          {otpLoading || loading ? (
            <span style={{ marginTop: "3px" }}>
              <Loader />
            </span>
          ) : (
            ""
          )}
          {loading ? <span>Validating OTP...</span> : <span>Resend OTP</span>}
        </div>

        <Button
          type="primary"
          htmlType="submit"
          className={styles.submit_button}
          disabled={loading || otpLoading || otp.length < 6}
          onClick={handleSubmit}
        >
          Validate
        </Button>
      </div>

      <div className={styles.more_choices}>
        <p>
          Entered a wrong email address?{" "}
          <button
            className={styles.action_button}
            onClick={() => setCurrentStep((prev) => prev - 1)}
          >
            Go back
          </button>
        </p>
        <p>
          Already have an account?{" "}
          <Link to="/login" className={styles.action_button}>
            Login
          </Link>
        </p>
      </div>
    </div>
  );
}

export default OtpVerification;
