export const apiEndpoints = {
  resetPasswordUrl: `/account/reset_password`,
  loginUrl: `/account/custom_login/`,
  changePasswordUrl: `/account/change_password/`,
  makePaymentFromWidget: `/eco/aggr_repay_loan_key`,
  getBorrowerLoansInWidget: `/eco/my_boro_loan_key`,
  getWidgetLoanDetails: "/eco/widget_loan_details",
  getProductByCode: `/misc/query_product_by_code`,
  validateBankAccount: `/account/validate_bank_account/`,
  updateBorrowerLoanStatusWithKey: `/eco/update_borrower_loan_status_key/`,
  getBorrowerProfile: `/account/my_boro_profile/`,
  checkOkraPaystackOnAccount: `/account/okra_paystack_details`,
  getAggregatorKey: `/widget/validate_aggregator_key`,
  getBorrowerLoanRepayments: `/eco/boro_man_repayment`,
  paystackInfo: `eco/widget_card`,
  getWallet: "/eco/widget_default_wallet",
  getWalletConsent: "/eco/widget_validate_wallet_consent",
  getTransactions: "/eco/widget_default_wallet_transactions",
  getSecurityQuestions: "/account/widget_security_question/all",
  saveSecurityQuestionAndAnswer: "/account/widget_security_question/answers",
  saveCustomerPin: "/account/widget_customer_pin",
  resetCustomerPin: "/account/widget_reset_customer_pin",
  withdrawFunds: "/account/widget_withdraw_funds",
  getLoanProducts: "/misc/query_product_key",
  processLoan: "/eco/process_borrower_loan_key",
  getLoanRepaymentSchedule: "/eco/widget_loan_repayments_schedule",
  makeRepayment: "/eco/widget_initiate_repayment",
  addCard: "/eco/widget_add_card",
  fetchCards: "/eco/widget_get_cards",
  setDefaultCard: "/eco/widget_set_default_card",
  getRepayments: "/eco/widget_loan_repayments",
  generateLoanDetails: "/eco/widget_generate_loan_schedule",
  getWidgetSettings: "/account/widget_aggregator_settings",
  getCollectionAccounts: "/collectionaccount/widget_search",
  offlineRepayment: "/eco/widget_manual_loan_repayment",
  checkCustomerExist: "/account/widget_check_customer_exist",
  saveBankStatementProvider: "/eco/widget_bank_statement_provider",
  contactUs: "/misc/widget_contact_us_form",
  checkBankProviderLinked: "/eco/widget_validate_bank_provider_linked",
  postLoanRepaymentViaMpesa: "/eco/widget_mpesa_loan_repayment",
  directDebitEmandatesAccts: "/eco/direct_debit_emandates",
  getLinkedBankAccountDetailsWidget: "/eco/widget_bank_account_details",
  fetchPendingLoanOfferWidget: "/eco/widget/get_pending_loan_offers",
  updateNewLoanOfferStatusWidget: "/eco/widget/update_loan_offer_status",
  createResidentialInformationWidget:
    "/eco/widget/create_customer_residential_information",
  confirmResidentialAddressWidget:
    "/eco/widget/customer_has_residential_information",
  getCustomerResidentialAddressWidget:
    "/eco/widget/get_customer_residential_information",
  // Embed Web Endpoints
  getCountries: "/account/all_country",
  getAggregators: "/account/aggregator/query",
  resolve_BVN: "/misc/verify_kyc",
  otp_types: "/enum/otp_use_types",
  validate_sign_up_email: "/account/verify",
  validate_sign_up_otp: "/account/otp/verify",
  generate_otp: "/account/otp/generate",
  login: "/account/custom_login",
  wallet_balance: "/account/get_funder_wallet_balance",
  customerBanks: "/account/signed_banks_country",
  customerLoanProducts: "/misc/query_embed_product_by_aggregator",
  customerSecurityQuestions: "/customers/security_questions",
  customerWalletTransactions: "/customers/wallet_transactions",
  customerOnboard: "/customers/onboard",
  customerWalletDetails: "/customers/wallet_details",
  customerProcessCustomerLoan: 
  "account/loan_applications/embed",
  customerSetPin: "/customers/set_pin",
  customerSecurityQuestionAnswer: "/customers/security_questions/answer",
  customerWithdrawFunds: "/wallet/withdraw",
  customerValidateBankAccount: "/account/validate_bank_account",
  customerResetPin: "/customers/reset_pin",
  customerLoanHistory: "/eco/loans/customer",
  getWebLoanRepaymentSchedule: "/eco/loan_repayments_schedule",
  getCustomerRepayments: "/eco/loan_repayments",
  updateUser: "/account/update_funder",
  updatePassword: "/account/change_password",
  repayloan: "/eco/loan/repayment",
  getWalletConsentWeb: "/eco/customer_validate_wallet_consent",
  checkCustomerExistsWeb: "/account/check_customer_exist",
  checkBankStatementProviderIsLinked: "/eco/validate_bank_provider_linked",
  saveBankstatementProviderWeb: "/eco/bank_statement_provider",
  customerOutstandingLoanBalance:
    "/statistics/customer/total_outstanding_loan_balance",
  loanDetails: "/eco/embed_loan_details",
  nextRepayment: "/eco/customer_next_loan_repayment",
  recentLoans: "/eco/customer_recent_loans",
  getBusinessName: "/customers/verify_business_registration_number",
  getTermsAndCondition: "/eco/loan_terms_and_conditions",
  getMandateConfirmation: "/clientwebhook/mandate_auth_confirmation",
  validateAggregator: "/account/validate_aggregator_shortname",
  getLinkedBankAccountDetailsWeb: "/eco/bank_account_details",
  fetchActiveLoans: "/eco/loans",
  fetchActiveLoansWidget: "/eco/widget_loans",
  createResidentialInformation: "/eco/create_customer_residential_information",
  fetchPendingLoanOffer: "/eco/get_pending_loan_offers",
  updateNewLoanOfferStatus: "/eco/update_loan_offer_status",
  confirmResidentialAddress: "/eco/customer_has_residential_information",
  getCustomerResidentialAddress: "/eco/get_customer_residential_information",
};
