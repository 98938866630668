import { combineReducers } from "redux";
import { updateWidgetSettingsReducer } from "./widgetSettingsReducer";
import { updateBorrowerDetailsReducer } from "./borrowerDetailsReducer";
import { updateLoanDetailsReducer } from "./loanDetailsReducer";
import { updateWebSettingsReducer } from "./websettingsReducer";
import { updateMonoWidgetDataReducer } from "./monoWidgetReducer";

const rootReducer = combineReducers({
  widgetSettingsReducer: updateWidgetSettingsReducer,
  borrowerDetailsReducer: updateBorrowerDetailsReducer,
  loanDetailsReducer: updateLoanDetailsReducer,
  webSettingsReducer: updateWebSettingsReducer,
  monoWidgetDataReducer: updateMonoWidgetDataReducer
});

export default rootReducer;
